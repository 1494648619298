import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbLandingBannerVideoBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  const [query, setQuery] = useState("");

  function handleSubmit(e) {
    if (e) e.preventDefault(e);
    if (!query) return;

    navigate("/product-search", { state: { query } });
  }

  return (
    <div
      className={`landing-banner-video ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.landingBannerVideo} ${classes.landingBannerVideoTheme}`}
    >
      <div className="video-wrapper">
        <iframe
          title={"video-banner-" + props.attributes.videoID}
          src={
            "https://player.vimeo.com/video/" +
            props.attributes.videoID +
            "?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=1&muted=1&background=1"
          }
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <div className="container">
        <div className="content">
          {blocks[0]}
          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="search">Search our products</label>
            <input
              type="text"
              name="search"
              placeholder="Name, Product, Brand, Search"
              value={query}
              minLength={2}
              required
              onChange={(e) => setQuery(e.target.value)}
            />
            <input type="submit" value="search" />
          </form>
        </div>
      </div>
    </div>
  );
};

CgbLandingBannerVideoBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbLandingBannerVideoBlockFragment = graphql`
  fragment CgbLandingBannerVideoBlock on WpBlock {
    ... on WpCgbLandingBannerVideoBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbLandingBannerVideoBlockAttributes {
          className
          videoID
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbLandingBannerVideoBlockPreview = `
    ... on CgbLandingBannerVideoBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbLandingBannerVideoBlockAttributes {
                className
                videoID
            }
        }
    }
`;
