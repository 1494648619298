export default (theme) => ({
  blogPostWrapper: {
    position: "relative",
    marginBottom: 36,
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      margin: "0 -12px",
      width: "calc(100% + 24px)",
      display: "flex",
      flexDirection: "row",
      padding: "50px 12px",
      justifyContent: "center",
    },
    "& .container": {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 75%",
        maxWidth: "75%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 62.5%",
        maxWidth: "62.5%",
        padding: "0 12px",
      },
      "& > * ": {
        marginBottom: 36,
      },
      "& > figure.wp-block-image": {
        marginBottom: "36px !important",
        [theme.breakpoints.up("md")]: {
          width: "120%",
          margin: "0 -10% 36px !important",
        },
        "& span.gatsby-image-wrapper": {
          width: "100% !important",
        },
        "&.full-width": {
          [theme.breakpoints.up("lg")]: {
            width: "160%",
            margin: "0 -30% 36px !important",
          },
        },
        "& figcaption": {
          fontSize: 10,
          textTransform: "uppercase",
          letterSpacing: "0.152em",
          lineHeight: 1.6,
          fontWeight: 500,
          padding: "28px 0",
          "& a": {
            color: theme.palette.secondary.main,
            transitoion: "color .3s ease-in-out",
            "&:hover, &:focus": {
              color: theme.palette.secondary.dark,
            },
          },
        },
      },
      "& > figure.wp-block-gallery": {
        margin: "0 auto 36px !important",
        "& .blocks-gallery-grid": {
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          [theme.breakpoints.up("sm")]: {
            width: "calc(100% + 24px)",
            margin: "0 -12px",
          },
          "& li": {
            display: "block",
            flex: "0 0 100%",
            maxWidth: "100%",
            marginBottom: 24,
            [theme.breakpoints.up("sm")]: {
              flex: "0 0 50%",
              maxWidth: "50%",
              padding: "0 12px",
            },
            "& figure": {
              display: "block",
              margin: 0,
              width: "100%",
              position: "relative",
              "& span.gatsby-image-wrapper": {
                width: "100% !important",
              },
              "& figcaption": {
                fontSize: 10,
                textTransform: "uppercase",
                letterSpacing: "0.152em",
                lineHeight: 1.6,
                fontWeight: 500,
                position: "absolute",
                right: 0,
                bottom: 0,
                backgroundColor: "white",
                padding: "30px 20px",
                width: 135,
                "& a": {
                  color: theme.palette.secondary.main,
                  transitoion: "color .3s ease-in-out",
                  "&:hover, &:focus": {
                    color: theme.palette.secondary.dark,
                  },
                },
              },
            },
          },
        },
        "&.columns-3": {
          "& .blocks-gallery-grid li": {
            [theme.breakpoints.up("sm")]: {
              flex: "0 0 33.333%",
              maxWidth: "33.333%",
              padding: "0 12px",
            },
          },
        },
        "&.columns-4": {
          "& .blocks-gallery-grid li": {
            [theme.breakpoints.up("sm")]: {
              flex: "0 0 25%",
              maxWidth: "25%",
              padding: "0 12px",
            },
          },
        },
        "&.columns-5": {
          "& .blocks-gallery-grid li": {
            [theme.breakpoints.up("sm")]: {
              flex: "0 0 20%",
              maxWidth: "20%",
              padding: "0 12px",
            },
          },
        },
      },
      "& > p.lead": {
        fontWeight: 500,
        fontSize: 18,
        [theme.breakpoints.up("md")]: {
          fontSize: 24,
        },
      },
      "& > p.breakout-lead": {
        fontWeight: 700,
        fontSize: 24,
        letterSpacing: "0.08em",
        lineHeight: 1.5,
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
          width: "120%",
          margin: "0 -10% 36px !important",
        },
      },
    },
    "& span.image-with-caption": {
      display: "block",
    },
  },
});
