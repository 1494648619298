import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Link from "../../Link";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbImageLinkBlock = (props) => {
  const classes = useStyles();

  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = props.attributes.localFile.publicURL;
    }
  } else {
    // This is a preview image
    image = props.attributes.mediaUrl;
  }

  return (
    <Link
      to={props.attributes.linkURL}
      style={{ backgroundImage: `url(${image})` }}
      className={`image-link ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.imageLinkSliderInner} ${classes.imageLinkSliderInnerTheme}`}
      state={props.attributes.state}
    >
      <div className="text-wrap">
        <span className="sub-text">{props.attributes.subText}</span>
        <span className="text">{props.attributes.text}</span>
      </div>
    </Link>
  );
};

CgbImageLinkBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkBlockFragment = graphql`
  fragment CgbImageLinkBlock on WpBlock {
    ... on WpCgbImageLinkBlock {
      name
      attributes {
        __typename
        ... on WpCgbImageLinkBlockAttributes {
          className
          subText
          text
          linkURL
          mediaUrl
          localFile {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkBlockPreview = `
    ... on CgbImageLinkBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkBlockAttributes {
                className
                subText
                text
                linkURL
                mediaUrl
            }
        }
    }
`;
