import pageTitle from "../../../images/page-title.png";
import icon from "../../../images/search.png";
import currentPage from "../../../images/pagination-dot.png";
import searching from "../../../images/searching.gif";

export default (theme) => ({
  howsToTitle: {
    position: "relative",
    padding: "32px 0",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "84px 0",
    },
    "& .heading": {
      flex: "0 0 100%",
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 56.25%",
        maxWidth: "56.25%",
      },
    },
    "& .search": {
      flex: "0 0 100%",
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 43.75%",
        maxWidth: "43.75%",
      },
    },
    "& .current-location": {
      padding: "16px 90px 0 40px",
      color: theme.palette.common.white,
      background: "transparent",
      border: 0,
      outline: "none",
      fontFamily: theme.bodyFont,
      textDecoration: "underline",
    },
    "& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
      marginBottom: 0,
      position: "relative",
      lineHeight: 1.2,
    },
    "&::before": {
      display: "block",
      width: "100vw",
      position: "absolute",
      zIndex: -1,
      content: '""',
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundImage: "url(" + pageTitle + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    "& form": {
      position: "relative",
      "& label": {
        position: "absolute",
        top: 12,
        left: 31,
        opacity: 0.3,
        textTransfrom: "uppercase",
        color: "white",
        textTransform: "uppercase",
        fontSize: 8,
        letterSpacing: "0.152em",
        lineHeight: 1.2,
        [theme.breakpoints.up("md")]: {
          top: 16,
          left: 41,
        },
      },
      '& input[type="text"]': {
        border: "1px solid white",
        borderRadius: 28,
        padding: "28px 60px 10px 30px",
        display: "block",
        margin: 0,
        width: "100%",
        lineHeight: 1,
        fontSize: 12,
        color: "white",
        backgroundColor: "transparent",
        fontFamily: theme.bodyFont,
        letterSpacing: "0.032em",
        outline: "none",
        [theme.breakpoints.up("md")]: {
          padding: "32px 90px 16px 40px",
          fontSize: 16,
          borderRadius: 36,
        },
        "& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder": {
          color: "white",
          opacity: 1,
        },
      },
      '& input[type="submit"]': {
        position: "absolute",
        outline: "none",
        top: 11,
        right: 11,
        width: 32,
        height: 32,
        border: "none",
        backgroundColor: "transparent",
        borderRadius: "50%",
        fontSize: 0,
        backgroundPosition: "center",
        backgroundImage: "url(" + icon + ")",
        backgroundSize: "cover",
        [theme.breakpoints.up("md")]: {
          width: 49,
          height: 49,
        },
      },
    },
  },
  searching: {
    padding: 72,
    paddingRight: 0,
    backgroundImage: "url(" + searching + ")",
    display: "block",
    width: "fit-content",
    margin: "0 auto",
    backgroundPosition: "center left",
    backgroundRepeat: "no-repeat",
    fontSize: 18,
  },
  results: {
    margin: "40px auto",
    [theme.breakpoints.up("md")]: {
      width: "87.5%",
    },
    "& .result-item": {
      border: "1px solid #DBDDE0",
      marginBottom: 32,
      width: "calc(100% - 1px) !important",
      outline: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex !important",
        flexDirection: "row",
        flexWrap: "wrap",
      },
      "& .gatsby-image-wrapper": {
        width: "316px",
      },
      "& img": {
        maxWidth: 316,
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 316px",
          margin: 0,
        },
      },
      "& .result-content": {
        padding: "24px 12px",
        display: "block",
        [theme.breakpoints.up("md")]: {
          flex: "1 0 auto",
          maxWidth: "calc(100% - 316px)",
          padding: "32px 64px",
        },
        "& p": {
          margin: "18px 0",
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: "0.032em",
          color: theme.palette.primary.main,
          "&.update": {
            marginTop: 0,
            letterSpacing: "0.152em",
            fontSize: 10,
            textTransform: "uppercase",
            lineHeight: 1.6,
          },
        },
        "& h4": {
          margin: "18px 0",
          fontSize: 24,
          lineHeight: 1.8,
          fontWeight: "500",
          color: theme.palette.primary.main,
        },
      },
    },
    "& ul.slick-dots": {
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main + "1A",
      position: "relative",
      margin: "36px 0",
      bottom: 0,
      "& li": {
        width: "auto",
        height: "auto",
        "& button": {
          fontWeight: 500,
          margin: "0 10px",
          borderRadius: 0,
          transition: "opacity .3s ease-in-out",
          backgroundColor: "transparent !important",
          border: "none",
          outline: "none",
          fontFamily: theme.bodyFont,
          fontSize: 18,
          color: theme.palette.primary.main,
          padding: "0 6px",
          width: "auto",
          height: "auto",
          "&:hover, &:focus": {
            opacity: ".8",
          },
          "& span": {
            display: "none",
          },
          "&::before": {
            display: "none",
          },
        },
        "&.slick-active button": {
          backgroundImage: "url(" + currentPage + ")",
          backgroundPosition: "center",
          color: theme.palette.primary.contrastText,
          width: 58,
          height: 80,
          paddingTop: 20,
          marginTop: -25,
          marginBottom: -5,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
  howToOther: {
    position: "relative",
    padding: "80px 0",
    "&::before": {
      backgroundColor: theme.palette.primary.main + "14",
      position: "absolute",
      content: '""',
      top: 0,
      bottom: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: -1,
    },
    "& h4": {
      marginBottom: 50,
    },
    "& .guide-listing": {
      display: "flex",
      width: "calc(100% + 24px)",
      margin: "0 -12px",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-around",
      "& a": {
        flex: "0 0 calc(50% - 24px)",
        maxWidth: "calc(50% - 24px)",
        backgroundColor: theme.palette.primary.main + "14",
        padding: "40px 32px 24px",
        fontWeight: "bold",
        textDecoration: "none",
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 2,
        textAlign: "center",
        color: theme.palette.primary.main,
        marginBottom: 24,
        display: "block",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(33.333% - 24px)",
          maxWidth: "calc(33.333% - 24px)",
        },
        "& .gatsby-image-wrapper": {
          width: 72,
          height: 72,
          borderRadius: "50%",
          overflow: "hidden",
          margin: "0 auto 24px",
          display: "block",
        },
        "& span": {
          display: "block",
        },
      },
    },
  },
});
