export default (theme) => ({
  ilrIntro: {
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
    boxShadow:
      "320px 0 " +
      theme.palette.secondary.main +
      ", -320px 0 " +
      theme.palette.secondary.main +
      ", 640px 0 " +
      theme.palette.secondary.main +
      ", -640px 0 " +
      theme.palette.secondary.main,
    padding: "12px 0",
    [theme.breakpoints.up("md")]: {
      padding: "40px 0",
    },
    "& .intro": {
      maxWidth: 540,
      marginBottom: 50,
      [theme.breakpoints.up("md")]: {
        marginBottom: 60,
        "& a": {
          position: "absolute",
          top: 24,
          right: 0,
          [theme.breakpoints.up("md")]: {
            top: 46,
          },
        },
      },
      "& h3, & p": {
        color: theme.palette.secondary.contrastText,
        marginBottom: 8,
      },
    },
  },
});
