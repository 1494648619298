import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbImageLinkRowIntroBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  return (
    <div
      className={`ilr-intro ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.ilrIntro}`}
    >
      <div className="intro">
        {blocks[1]}
        {blocks[2]}
        {blocks[0]}
      </div>
      {blocks[3]}
    </div>
  );
};

export const CgbImageLinkRowIntroBlockFragment = graphql`
  fragment CgbImageLinkRowIntroBlock on WpBlock {
    ... on WpCgbImageLinkRowIntroBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkRowIntroBlockPreview = `
    ... on CgbImageLinkRowIntroBlock {
        name
        attributes {
            className
        }
    }
`;
