import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { graphql, useStaticQuery } from "gatsby";
import { CoreHeadingBlock } from "../CoreHeadingBlock";
import Link from "../../Link";
import Img from "gatsby-image";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import { CoreButtonBlock } from "../CoreButtonBlock";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbLatestPostsBlock = (props) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query LatestPostsList {
      wp {
        themeOptions {
          blogPath
        }
      }
      allWpPost {
        nodes {
          slug
          title
          date(formatString: "DD MMMM YYYY")
          excerpt
          featuredImage {
            node {
              mediaItemUrl
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let image = null;

  // Use the gatsby image if available
  if (data.allWpPost.nodes[0].featuredImage) {
    if (data.allWpPost.nodes[0].featuredImage.node.localFile) {
      if (
        data.allWpPost.nodes[0].featuredImage.node.localFile.childImageSharp
      ) {
        image = (
          <Img
            fluid={
              data.allWpPost.nodes[0].featuredImage.node.localFile
                .childImageSharp.fluid
            }
            alt=""
          />
        );
      } else {
        // Gifs do not have a childImageSharp
        image = (
          <img
            src={data.allWpPost.nodes[0].featuredImage.node.localFile.publicURL}
            alt=""
          />
        );
      }
    } else {
      // This is a preview image
      image = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingBottom: "55%" }} />
          <img
            src={data.allWpPost.nodes[0].featuredImage.node.mediaUrl}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      );
    }
  }

  return (
    <div
      className={`latest-posts ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.latestPosts} ${classes.latestPostsTheme}`}
    >
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: ReactHtmlParser(props.attributes.sectionTitle),
          level: 3,
          textColor: "",
          backgroundColor: "",
          __typename: "WpconBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <div className="latest-post-grid">
        <div className="newest">
          <Link
            to={`/${data.wp.themeOptions.blogPath}/${data.allWpPost.nodes[0].slug}/`}
          >
            {image}
            <span className="con-wrap">
              <span className="date">{data.allWpPost.nodes[0].date}</span>
              <span className="title">{data.allWpPost.nodes[0].title}</span>
              {ReactHtmlParser(
                data.allWpPost.nodes[0].excerpt
                  .replace("<p>", '<span class="excerpt">')
                  .replace("</p>", "</span>")
              )}
            </span>
          </Link>
        </div>
        <div className="more">
          {data.allWpPost.nodes[1] ? (
            <>
              <Link
                to={`/${data.wp.themeOptions.blogPath}/${data.allWpPost.nodes[1].slug}/`}
                className="more-link"
              >
                <span className="date">{data.allWpPost.nodes[1].date}</span>
                <span className="title">{data.allWpPost.nodes[1].title}</span>
                {ReactHtmlParser(
                  data.allWpPost.nodes[1].excerpt
                    .replace("<p>", '<span class="excerpt">')
                    .replace("</p>", "</span>")
                )}
              </Link>
            </>
          ) : (
            ""
          )}

          {data.allWpPost.nodes[2] ? (
            <>
              <Link
                to={`/${data.wp.themeOptions.blogPath}/${data.allWpPost.nodes[2].slug}/`}
                className="more-link"
              >
                <span className="date">{data.allWpPost.nodes[2].date}</span>
                <span className="title">{data.allWpPost.nodes[2].title}</span>
                {ReactHtmlParser(
                  data.allWpPost.nodes[2].excerpt
                    .replace("<p>", '<span class="excerpt">')
                    .replace("</p>", "</span>")
                )}
              </Link>
            </>
          ) : (
            ""
          )}
          <CoreButtonBlock
            attributes={{
              align: "",
              backgroundColor: "tertiary",
              borderRadius: 0,
              className: "view-all",
              gradient: "",
              linkTarget: "",
              placeholder: "",
              rel: "",
              text: props.attributes.btnText,
              textColor: "secondary",
              title: "",
              url: `/${data.wp.themeOptions.blogPath}/`,
              __typename: "WpCoreButtonBlockAttributes",
            }}
          />
        </div>
      </div>
    </div>
  );
};

CgbLatestPostsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbLatestPostsBlockFragment = graphql`
  fragment CgbLatestPostsBlock on WpBlock {
    ... on WpCgbLatestPostsBlock {
      name
      attributes {
        __typename
        ... on WpCgbLatestPostsBlockAttributes {
          className
          sectionTitle
          btnText
        }
      }
    }
  }
`;

export const CgbLatestPostsBlockPreview = `
... on CgbLatestPostsBlock {
  name
  attributes {
    __typename
    ... on CgbLatestPostsBlockAttributes {
      className
      sectionTitle
      btnText
    }
  }
}
`;
