export default (theme) => ({
  latestPosts: {
    marginBottom: 36,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 44,
    },
    "& h3": {
      marginBottom: 24,
    },
    "& .latest-post-grid": {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "calc(100% + 24px)",
        margin: "0 -12px 36px",
      },
      "& .newest": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 63.6%",
          padding: "0 12px",
          maxWidth: "64.6%",
        },
        "& a": {
          display: "block",
          textDecoration: "none",
          color: theme.palette.primary.contrastText,
          marginBottom: 48,
          position: "relative",
          "& .con-wrap": {
            display: "block",
            padding: 30,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.up("md")]: {
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              padding: "25px 36px",
              backgroundColor: theme.palette.primary.main + "5E",
              backdropFilter: "blur(5px)",
            },
            "& .date": {
              fontSize: 8,
              lineHeight: 2,
              letterSpacing: "0.152em",
              marginBottom: 12,
              display: "block",
              textTransform: "uppercase",
            },
            "& .title": {
              display: "block",
              marginBottom: 15,
              fontSize: 20,
              lineHeight: 1.4,
              letterSpacing: "0.008em",
              fontWeight: "700",
              [theme.breakpoints.up("md")]: {
                fontSize: 32,
                lineHeight: 1.5,
              },
            },
            "& .excerpt": {
              opacity: 0.8,
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: "0.016em",
              textShadow: "0 0 2px " + theme.palette.primary.main,
            },
          },
        },
      },
      "& .more": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 36.3%",
          padding: "0 12px",
          maxWidth: "64.6%",
          paddingTop: 24,
        },
        "& a.more-link": {
          display: "block",
          borderBottom: "1px solid #f0f0f0",
          marginBottom: 30,
          paddingBottom: 20,
          textDecoration: "none",
          color: theme.palette.primary.main,
          "& .date": {
            fontSize: 8,
            lineHeight: 2,
            letterSpacing: "0.152em",
            marginBottom: 10,
            display: "block",
            textTransform: "uppercase",
            opacity: 0.24,
          },
          "& .title": {
            display: "block",
            marginBottom: 15,
            fontSize: 16,
            lineHeight: 2,
            letterSpacing: "0.008em",
            fontWeight: "700",
            [theme.breakpoints.up("md")]: {
              fontSize: 18,
            },
          },
          "& .excerpt": {
            opacity: 0.5,
            fontSize: 14,
            lineHeight: 1.72,
            letterSpacing: "0.032em",
            height: 48,
            overflow: "hidden",
            display: "block",
          },
        },
      },
    },
    "& .view-all": {
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 44,
        right: 0,
      },
    },
  },
  latestPostsTheme: {
    ...theme.latestPostsTheme,
  },
});
