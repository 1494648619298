import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import ProductsGrid from "../../Products/Components/ProductsGrid";
import ReactHtmlParser from "react-html-parser";
import Link from "../../Link";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => styles(theme));

export const AcfSpecifiersProductsBlock = (props) => {
  const classes = useStyles();

  const [query, setQuery] = useState("");

  const data = useStaticQuery(graphql`
    query SpecProducts {
      wpMenu(locations: { in: HEADER_MENU }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
            cssClasses
            MenuItemImage {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            childItems {
              nodes {
                id
                label
                path
                cssClasses
                MenuItemImage {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  function handleSubmit(e) {
    if (e) e.preventDefault(e);
    if (!query) return;

    navigate("/product-search", { state: { query } });
  }

  // menuItemsChildren
  const menuItemsChildren = data.wpMenu.menuItems.nodes[0].childItems.nodes;

  return (
    <div
      className={`spec-products ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.specProducts}`}
    >
      <div className="prod-cats">
        <h2>Our Products</h2>
        <div className="links">
          {menuItemsChildren.map((item) => (
            <Link
              className={`full-link ${item.cssClasses.join(" ")}`}
              key={item.id}
              to={item.path}
            >
              <span className="content">
                <span>View All</span>
                <span>{ReactHtmlParser(item.label)}</span>
              </span>
              {item.MenuItemImage.image !== null && (
                <span className="image">
                  <Img
                    fluid={
                      item.MenuItemImage.image.localFile.childImageSharp.fluid
                    }
                  />
                </span>
              )}
            </Link>
          ))}
        </div>
      </div>
      <div className="latest-products">
        <h3>Latest Innovations</h3>
        <ProductsGrid products={props.block.acfSpecifiersProducts.products} />
      </div>
      <div className="search">
        <h3>Find the right product</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="search">Search our products</label>
          <input
            type="text"
            name="search"
            placeholder="Name, Product, Brand, Search"
            value={query}
            minLength={2}
            required
            onChange={(e) => setQuery(e.target.value)}
          />
          <input type="submit" value="search" />
        </form>
      </div>
    </div>
  );
};

AcfSpecifiersProductsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const AcfSpecifiersProductsBlockFragment = graphql`
  fragment AcfSpecifiersProductsBlock on WpBlock {
    ... on WpAcfSpecifiersProductsBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpAcfSpecifiersProductsBlockAttributes {
          className
        }
      }
      acfSpecifiersProducts {
        products {
          ... on WpProduct {
            id
            title
            uri
            link
            status
            featuredImage {
              node {
                sourceUrl
              }
            }
            product {
              code
              description
              fieldGroupName
              fixDescription
              fixGuide
              fixVideo
              hasHowtoGuide
              installDescription
              installGuide
              installVideo
              introduction
              overview
              shortDescription
            }
            brands {
              nodes {
                BrandExtras {
                  logo {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfSpecifiersProductsBlockPreview = `
    ... on AcfSpecifiersProductsBlock {
        name
        originalContent
        attributes {
            __typename
            ... on AcfSpecifiersProductsBlockAttributes {
                className
            }
        }
        acfSpecifiersProducts {
          products {
            ... on Product {
                id
                title
                uri
                link
                status
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
                product {
                  code
                  description
                  fieldGroupName
                  fixDescription
                  fixGuide
                  fixVideo
                  hasHowtoGuide
                  installDescription
                  installGuide
                  installVideo
                  introduction
                  overview
                  shortDescription
                }
                brands {
                    nodes {
                        BrandExtras {
                            logo {
                              sourceUrl
                            }
                        }
                    }
                }
            }
          }
        }
    }
`;
