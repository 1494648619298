import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import { graphql, useStaticQuery } from "gatsby";
import { CoreHeadingBlock } from "../CoreHeadingBlock";
import Link from "../../Link";
import Img from "gatsby-image";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbPopularProductsBlock = (props) => {
  const blocks = buildBlocks(
    props?.innerBlocks,
    props?.parentAttributes,
    props?.pageContext
  );
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query PopularProducts {
      wp {
        themeOptions {
          ThemeOptions {
            popularProducts {
              ... on WpProduct {
                id
                title
                uri
                product {
                  productGallery {
                    id
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let count = 0;

  return (
    <div
      className={`popular-products ${
        props?.attributes?.className ? props?.attributes?.className : ""
      } ${classes.popularProducts} ${classes.popularProductsTheme}`}
    >
      {blocks && (
        <div className="intro">
          {blocks.map((block) => {
            return block;
          })}
        </div>
      )}
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "after-line",
          content: "Popular Products",
          level: 5,
          textColor: "",
          backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <div className="product-grid">
        {data.wp.themeOptions.ThemeOptions.popularProducts !== null &&
          data.wp.themeOptions.ThemeOptions.popularProducts.map((product) => {
            count++;
            if (count <= 10) {
              return (
                <Link to={product.uri} key={product.id}>
                  {product.product.productGallery.length !== 0 && (
                    <Img
                      fluid={
                        product.product.productGallery[0].localFile
                          .childImageSharp.fluid
                      }
                      alt=""
                      className="product-image"
                    />
                  )}
                  <span>{product.title}</span>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

CgbPopularProductsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbPopularProductsBlockFragment = graphql`
  fragment CgbPopularProductsBlock on WpBlock {
    ... on WpCgbPopularProductsBlock {
      name
      attributes {
        __typename
        ... on WpCgbPopularProductsBlockAttributes {
          className
        }
      }
    }
  }
`;

export const CgbPopularProductsBlockPreview = `
... on CgbPopularProductsBlock {
  name
  attributes {
    __typename
    ... on CgbPopularProductsBlockAttributes {
      className
    }
  }
}
`;
