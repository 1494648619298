export default (theme) => ({
  waveSpacer: {
    overflow: "hidden",
    width: "100vw",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    fontSize: 0,
    backgroundColor: theme.palette.primary.main,
    "&.bottom-secondary": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&.bottom-tertiary": {
      backgroundColor: theme.palette.tertiary.main,
    },
    "&.bottom-black": {
      backgroundColor: theme.palette.common.black,
    },
    "&.bottom-white": {
      backgroundColor: theme.palette.common.white,
    },
    "&.top-primary svg .top": {
      fill: theme.palette.common.white,
    },
    "&.top-secondary svg .top": {
      fill: theme.palette.secondary.main,
    },
    "&.top-tertiary svg .top": {
      fill: theme.palette.tertiary.main,
    },
    "&.top-black svg .top": {
      fill: theme.palette.common.black,
    },
    "& svg": {
      height: 170,
      marginLeft: "50%",
      transform: "translateX(-50%)",
      marginBlock: "-1px",
      width: "100vw",
      minWidth: 1800,
      "& .top": {
        fill: theme.palette.common.white,
      },
      "& .bottom": {
        fill: "transparent",
      },
    },
  },
});
