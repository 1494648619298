export default theme => ({
    contactColumns: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: 'calc(100% + 36px)',
        margin: '0 -18px 36px',
        justifyContent: 'space-between',
        '& .content-col': {
            flex: '0 0 100%',
            maxWidth: '100%',
            padding: '80px 18px 0',
            marginBottom: 32,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 66.666%',
                maxWidth: '66.666%',
                marginBottom: 120
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 50%',
                maxWidth: '50%'
            },
            '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                maxWidth: 370,
            },
            '& .image-links': {
                marginTop: 36,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                [theme.breakpoints.up('md')]: {
                    marginTop: 72,
                },
                '& .image-link': {
                    paddingBottom: '69%',
                    marginBottom: 24,
                    [theme.breakpoints.up('sm')]: {
                        flex: '0 0 calc(50% - 12px)',
                        maxWidth: 'calc(50% - 12px)',
                        marginBottom: 0,
                    },
                }
            }
        },
        '& .form-col': {
            flex: '0 0 100%',
            maxWidth: '100%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: 32,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 33.333%',
                maxWidth: '33.333%',
                boxShadow: '200px 0 0 0 ' + theme.palette.primary.main,
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 31.25%%',
                maxWidth: '31.25%%',
                paddingLeft: 80,
                paddingRight: 0,
                paddingTop: 80,
                paddingBottom: 0,
            },
            '& h3': {
                fontWeight: 700,
                color: theme.palette.primary.contrastText,
                letterSpacing: '0.008em',
                lineHeight: 1.5,
                fontSize: 28,
                marginBottom: 12,
                marginTop: 0,
                [theme.breakpoints.up('md')]: {
                    fontSize: 32,
                }
            },
            '& p': {
                color: theme.palette.primary.contrastText,
                letterSpacing: '0.032em',
                lineHeight: 1.5,
            }
        }
    },
    contactColumnsTheme: {
        ...theme.imageLinkRowTheme
    },
})

//136