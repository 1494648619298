import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbPageTitleBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  return (
    <div
      className={`page-title ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.pageTitle} ${classes.pageTitleTheme}`}
    >
      {blocks.map((block) => {
        return block;
      })}
    </div>
  );
};

CgbPageTitleBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbPageTitleBlockFragment = graphql`
  fragment CgbPageTitleBlock on WpBlock {
    ... on WpCgbPageTitleBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbPageTitleBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbPageTitleBlockPreview = `
    ... on CgbPageTitleBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbPageTitleBlockAttributes {
                className
            }
        }
    }
`;
