import React from "react";
import { navigate } from "gatsby";
import { isBrowser } from "../../helpers";
import data from "../../../.brew-cache/data.json";

const GoBack = ({ fallback, pathname }) => {
  function goBack() {
    if (!isBrowser) return;

    if (!window.history.state && fallback) {
      const fallbackUrl = fallback;
      navigate(fallbackUrl);
      return;
    }

    if (!window.history.state && !fallback) {
      const pathParts = pathname.split("/").filter((part) => part !== "");
      const path = pathParts.slice(0, pathParts.length - 1).join("/");
      navigate(`/${path}`);
      return;
    }

    navigate(-1);
    return;
  }

  return (
    <button className="go-back" onClick={() => goBack()}>
      Back
    </button>
  );
};

export default GoBack;
