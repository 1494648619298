import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
// import ReactHtmlParser from 'react-html-parser';
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbBreakoutIntroBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();
  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = (
        <Img fluid={props.attributes.localFile.childImageSharp.fluid} alt="" />
      );
    } else {
      // Gifs do not have a childImageSharp
      image = <img src={props.attributes.localFile.publicURL} alt="" />;
    }
  } else {
    // This is a preview image
    image = (
      <div className="gatsby-image-wrapper">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={props.attributes.mediaUrl}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={`breakout-gallery-intro ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.breakoutIntro} ${classes.bannerMessageTheme} ${
        classes.breakoutIntroTheme
      }`}
      data-header-bg={props.attributes.background}
    >
      <div className="image-wrapper">{image}</div>
      <div className="content-wrap">
        <h1 className="header">{props.attributes.tag}</h1>
        <div className="bold-con">{blocks[0]}</div>
        <div className="standard-con">{blocks[1]}</div>
      </div>
    </div>
  );
};

CgbBreakoutIntroBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbBreakoutIntroBlockFragment = graphql`
  fragment CgbBreakoutIntroBlock on WpBlock {
    ... on WpCgbBreakoutIntroBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbBreakoutIntroBlockAttributes {
          className
          tag
          mediaUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBreakoutIntroBlockPreview = `
    ... on CgbBreakoutIntroBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBreakoutIntroBlockAttributes {
                className
                tag
                mediaUrl
            }
        }
    }
`;
