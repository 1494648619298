import arrowWhite from "../../../images/button-arrow-white.png";
import icon from "../../../images/search-white.png";

export default (theme) => ({
  specProducts: {
    position: "relative",
    padding: "100px 0",
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      padding: "170px 0",
      marginBottom: 100,
    },
    "&::before": {
      backgroundColor: `${theme.palette.primary.main}14`,
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      zIndex: -1,
    },
    "& .prod-cats": {
      "& h2": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: 1.333,
        fontSize: 32,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        whiteSpace: "nowrap",
        marginTop: 0,
        marginBottom: 32,
        [theme.breakpoints.up("md")]: {
          fontSize: 48,
          marginBottom: 70,
        },
        "&:after": {
          content: '""',
          marginLeft: 24,
          height: 32,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          opacity: 0.3,
          display: "block",
          flex: "1",
          [theme.breakpoints.up("md")]: {
            height: 48,
          },
        },
      },
      "& .links": {
        gap: "1rem",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        marginBottom: 32,
        [theme.breakpoints.up("md")]: {
          marginBottom: 70,
        },
      },
      "& a.full-link": {
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        textDecoration: "none",
        outline: "none",
        width: "100%",

        // "&:nth-of-type(1), &:nth-of-type(1) .image:before": {
        //   backgroundColor: "#307FE2",
        // },
        // "&:nth-of-type(2), &:nth-of-type(2) .image:before": {
        //   backgroundColor: "#00AD9F",
        // },
        // "&:nth-of-type(3), &:nth-of-type(3) .image:before": {
        //   backgroundColor: "#FF8200",
        // },
        "&.overview": {
          "& .image": {
            fontSize: 0,
            position: "relative",
            flex: "0 0 135px",
            maxWidth: "135px",
            "& .gatsby-image-wrapper": {
              "& > div": {
                paddingBottom: "210px !important",
              },
            },
          },
        },
        "& .content": {
          padding: "0 20px 24px 20px",
          "& span": {
            color: `${theme.palette.primary.contrastText}CC`,
            display: "block",
            fontSize: 12,
            letterSpacing: "0.016em",
            lineHeight: 2,
            "&:nth-of-type(2)": {
              color: theme.palette.secondary.contrastText,
              fontWeight: 700,
              letterSpacing: "-0.032em",
              lineHeight: 1.2,
              fontSize: 24,
            },
          },
        },
        "& .image": {
          fontSize: 0,
          position: "relative",
          flex: "0 0 180px",
          maxWidth: "180px",
          "& .gatsby-image-wrapper": {
            "& > div": {
              paddingBottom: "150px !important",
            },
          },
          "& img, & .gatsby-image-wrapper": {
            transition: "opacity .3s ease-in-out",
          },
          "&::before": {
            backgroundImage: `url(${arrowWhite})`,
            width: 40,
            height: 40,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            content: '""',
            display: "block",
            position: "absolute",
            bottom: 15,
            left: -2,
            zIndex: 1,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        "&:hover .image img, &:hover .gatsby-image-wrapper, &:focus .image img, &:focus .gatsby-image-wrapper": {
          opacity: 0.8,
        },
      },
    },
    "& .latest-products": {
      marginBottom: 32,
      "& h3": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        letterSpacing: "0",
        lineHeight: 1.333,
        fontSize: 24,
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        whiteSpace: "nowrap",
        marginTop: 0,
        marginBottom: 24,
        "&:after": {
          content: '""',
          marginLeft: 24,
          height: 24,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          opacity: 0.3,
          display: "block",
          flex: "1",
        },
      },
      "& .MuiGrid-grid-lg-4": {
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 25% !important",
          maxWidth: "25% !important",
        },
      },
    },
    "& .search": {
      display: "block",
      backgroundColor: "#E1E4E9",
      padding: 50,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& h3": {
        fontSize: 24,
        fontFamily: theme.headerFont,
        fontWeight: "bold",
        marginBottom: 24,
        letterSpacing: "0.008em",
        lineHeight: 1.33,
        marginTop: 0,
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
          marginBottom: 0,
          maxWidth: 300,
        },
      },
      "& form": {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 45%",
          maxWidth: 530,
        },
        "& label": {
          position: "absolute",
          top: 12,
          left: 31,
          opacity: 0.3,
          textTransfrom: "uppercase",
          color: theme.palette.primary.main,
          textTransform: "uppercase",
          fontSize: 8,
          letterSpacing: "0.152em",
          lineHeight: 1.2,
          [theme.breakpoints.up("md")]: {
            top: 16,
            left: 41,
          },
        },
        '& input[type="text"]': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 28,
          padding: "28px 60px 10px 30px",
          display: "block",
          margin: 0,
          width: "100%",
          lineHeight: 1,
          fontSize: 12,
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
          fontFamily: theme.bodyFont,
          letterSpacing: "0.032em",
          outline: "none",
          [theme.breakpoints.up("md")]: {
            padding: "32px 90px 16px 40px",
            fontSize: 16,
            maxWidth: 530,
            borderRadius: 36,
          },
          "& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder": {
            color: "white",
            opacity: 1,
          },
        },
        '& input[type="submit"]': {
          position: "absolute",
          outline: "none",
          top: 11,
          right: 11,
          width: 32,
          height: 32,
          border: "none",
          backgroundColor: "transparent",
          borderRadius: "50%",
          fontSize: 0,
          backgroundPosition: "center",
          backgroundImage: `url(${icon})`,
          backgroundSize: "cover",
          [theme.breakpoints.up("md")]: {
            width: 49,
            height: 49,
          },
        },
      },
    },
  },
});
