export default (theme) => ({
  popularProducts: {
    marginBottom: 36,
    position: "relative",
    "& .intro": {
      maxWidth: 540,
      marginBottom: 50,
      "& h4, & p": {
        marginBottom: 8,
      },
    },
    "& .product-grid": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "calc(100% + 24px)",
      margin: "0 -12px 0",
      "& a": {
        flex: "0 0 calc(50% - 24px)",
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: "0 6px 14px rgba(0,0,0,0.16)",
        padding: "0 14px 24px",
        textDecoration: "none",
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 1.33,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        margin: "0 12px 24px",
        transition: "opacity .3s ease-in-out",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(20% - 24px)",
        },
        "&:hover, &:focus": {
          opacity: 0.9,
        },
        "& .gatsby-image-wrapper": {
          margin: "12px 0",
        },
      },
    },
  },
  popularProductsTheme: {
    ...theme.popularProductsTheme,
  },
});
