import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Slider from "react-slick";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";

import styles from "./styles";
import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import { CoreButtonBlock } from "../../blocks/CoreButtonBlock";

const useStyles = makeStyles((theme) => styles(theme));

const queryObject = (next = "") => {
  return {
    query: `
    {
      products(first: 50, after: "${next}") {
        edges {
          node {
            id
            link
            slug
            title
            modified
            product {
              code
              shortDescription
              faqs {
                answer
                fieldGroupName
                question
              }
              productGallery {
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

export const CgbProductSearchBlock = (props) => {
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [nextPage, setNextPage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState("");

  const settings = {
    dots: true,
    slidesToShow: 1,
    speed: 0,
    variableWidth: true,
    rows: 6,
    swipe: false,
    adaptiveHeight: true,
    afterChange: () => setTimeout(() => scrollTo(`.${classes.results}`), 100),
  };

  useEffect(() => {
    if (nextPage) {
      fetchProducts(nextPage);
    }
  }, [nextPage]); //eslint-disable-line

  function handleSubmit(e) {
    if (e) e.preventDefault();

    setProducts([]);

    setSearching(true);
    setQuery(search);

    fetchProducts("", true);
  }

  function fetchProducts(next = "", newSearch) {
    axios
      .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObject(next), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return {
          products: res.data.data.products.edges.map((node) => node.node),
          next: res.data.data.products.pageInfo.endCursor,
          hasNextPage: res.data.data.products.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        return {
          products: data.products.filter((product) => product.product.faqs),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        if (!search) return data;

        return {
          products: data.products.filter((product) => {
            if (product.title.toLowerCase().includes(search.toLowerCase()))
              return true;
            if (product.product.code === Number(search)) return true;
            return false;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        if (newSearch) {
          setProducts(data.products);
        } else {
          setProducts(products.concat(data.products));
        }
        data.hasNextPage && setNextPage(data.next);

        if (!data.hasNextPage) {
          setNextPage(false);
          setSearching(false);
        }
      });
  }

  function renderImage(product) {
    if (
      product.product.productGallery &&
      product.product.productGallery[0] &&
      product.product.productGallery[0].sourceUrl
    ) {
      return (
        <img
          src={product.product.productGallery[0].sourceUrl}
          alt={product.title}
        />
      );
    }
    if (
      product.product.productGallery &&
      product.product.productGallery[0] &&
      product.product.productGallery[0].localFile.childImageSharp.fluid
    ) {
      return (
        <Img
          fluid={
            product.product.productGallery[0].localFile.childImageSharp.fluid
          }
          alt={product.title}
        />
      );
    }

    return null;
  }

  function renderError() {
    return (
      <>
        <p>Your search hasn’t matched any of our available FAQs.</p>
        <p>Looking for information on something specific?</p>
        <p>
          Get in touch with the experts at TYDE today via the{" "}
          <Link to="/about-us/contact-us/">contact page</Link>.
        </p>
      </>
    );
  }

  return (
    <section>
      <div className={"page-title " + classes.howsToTitle}>
        <div className="heading">
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: "FAQs",
              level: 1,
              textColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
        </div>
        <div className="search">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="search">Search for your product</label>
            <input
              type="text"
              name="search"
              minlength="2"
              required
              placeholder="Product Number, Product Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <input type="submit" value="search" />
          </form>
        </div>
      </div>

      {searching && <div className={classes.searching}>Searching...</div>}

      {products.length > 0 && !nextPage && (
        <section>
          <div className={classes.results}>
            <Slider {...settings}>
              {products.length &&
                products.map((product, index) => {
                  return (
                    <div key={index} className="result-item">
                      {renderImage(product)}
                      <div className="result-content">
                        <p className="update">
                          Last updated {product.modified}
                        </p>
                        <h4>{product.title}</h4>
                        <p>{product.product.shortDescription}</p>
                        <CoreButtonBlock
                          attributes={{
                            align: "",
                            backgroundColor: "tertiary",
                            borderRadius: 0,
                            className: "view-all",
                            gradient: "",
                            linkTarget: "",
                            placeholder: "",
                            rel: "",
                            text: "Read FAQs",
                            textColor: "secondary",
                            title: "",
                            url: "/products/" + product.slug,
                            linkState: { scroll: true },
                            __typename: "WpCoreButtonBlockAttributes",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </section>
      )}

      {query && !searching && !products.length && renderError()}
    </section>
  );
};

CgbProductSearchBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbProductSearchBlockFragment = graphql`
  fragment CgbProductSearchBlock on WpBlock {
    ... on WpCgbProductSearchBlock {
      name
      attributes {
        __typename
        ... on WpCgbProductSearchBlockAttributes {
          className
        }
      }
    }
  }
`;

export const CgbProductSearchBlockPreview = `
... on CgbProductSearchBlock {
  name
  attributes {
    __typename
    ... on CgbProductSearchBlockAttributes {
      className
    }
  }
}
`;
