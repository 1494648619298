import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Pagination from "@material-ui/lab/Pagination";
import themeOptions from "../../../../.brew-cache/theme.json";
import Link from "../../Link";
import { replaceMasks } from "../../../services/masks";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreButtonBlock } from "../CoreButtonBlock";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbCasestudyPagePostsBlock = (props) => {
  const classes = useStyles();
  /**
   * The blog posts should be passed through from the parent page directly to this component
   * This will only happen on custom page templates like the blog list page
   * Preview will not have the posts information for this page due to how the query works, so if this not set
   * use some placeholder data to populate the posts
   * */
  let { pageNumber, totalPages, featuredPost } = props.pageContext;
  let { posts: rawPosts } = props.parentAttributes;

  featuredPost = featuredPost?.node;

  const data = useStaticQuery(graphql`
    query DefaultCSFeaturedImage {
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              postFeaturedImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // Toggle output based on Attributes
  // props.attributes.display : list / grid

  let defaultImage = (
    <Img
      fluid={
        data.wp.themeOptions.ThemeOptions.defaultImages.postFeaturedImage
          .localFile.childImageSharp.fluid
      }
    />
  );

  let featuredPostImage = null;

  if (featuredPost) {
    // This is a preview image
    if (featuredPost.featuredImage === null) {
      featuredPostImage = defaultImage;
    } else {
      featuredPostImage = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingBottom: 540 }} />
          <img
            src={
              featuredPost?.featuredImage?.node?.localFile?.childImageSharp
                ?.fluid?.src
            }
            alt={featuredPost.featuredImage?.node?.altText}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      );
    }
  }

  // map over posts
  const posts = rawPosts
    .map((post) => post.node)
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

  return (
    <div
      className={`cgbCasestudyPagePostsBlock ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}
    >
      <div className="page-title">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: ReactHtmlParser(props.attributes.listingTitle),
            level: 1,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
      </div>
      <div className="blog-list">
        {/* {featuredPost && (
          <Link
            to={`${featuredPost.uri}`}
            style={{ textDecoration: "none" }}
            className="featured-item"
          >
            {featuredPostImage}
            <span className="con-wrap">
              <span className="date">{featuredPost.date}</span>
              <span className="title">{featuredPost.title}</span>
              {featuredPost?.excerpt &&
                ReactHtmlParser(
                  featuredPost.excerpt
                    .replace("<p>", '<span class="excerpt">')
                    .replace("</p>", "</span>")
                )}
            </span>
          </Link>
        )} */}

        {posts.map((post) => {
          let image = null;
          // This is a preview image
          if (!post?.featuredImage?.node?.localFile) {
            image = defaultImage;
          } else {
            image = (
              <Img
                fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
              />
            );
          }

          return (
            <div className="list-item" key={post.id}>
              {image}
              <div className="con-wrap">
                <p className="date">{post.date}</p>
                <h2>{post.title}</h2>
                {post?.excerpt && (
                  <p>
                    {ReactHtmlParser(
                      replaceMasks(post?.excerpt.replace(/(<([^>]+)>)/gi, ""))
                    )}
                  </p>
                )}

                <CoreButtonBlock
                  attributes={{
                    align: "",
                    backgroundColor: "tertiary",
                    borderRadius: 0,
                    className: "view-all",
                    gradient: "",
                    linkTarget: "",
                    placeholder: "",
                    rel: "",
                    text: props.attributes.btnText,
                    textColor: "secondary",
                    title: "",
                    url: `${post.uri}`,
                    __typename: "WpCoreButtonBlockAttributes",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={pageNumber}
          onChange={(e, value) => {
            value === 1
              ? navigate(`/case-studies`)
              : navigate(`/case-studies/page/${value}`);
          }}
        />
      )}
    </div>
  );
};

CgbCasestudyPagePostsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbCasestudyPagePostsBlockFragment = graphql`
  fragment CgbCasestudyPagePostsBlock on WpBlock {
    ... on WpCgbCasestudyPagePostsBlock {
      name
      attributes {
        __typename
        ... on WpCgbCasestudyPagePostsBlockAttributes {
          className
          display
          btnText
          listingTitle
        }
      }
    }
  }
`;

export const CgbCasestudyPagePostsBlockPreview = `
... on CgbCasestudyPagePostsBlock {
  name
  attributes {
    __typename
    ... on CgbCasestudyPagePostsBlockAttributes {
      className
      display
      btnText
      listingTitle
    }
  }
}
`;
